<template>
  <v-app>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group
        id="input-group-title"
        label="Judul:"
        label-for="input-title"
      >
        <b-form-input
          id="input-title"
          v-model="form.title"
          placeholder="Judul Pengumuman"
        ></b-form-input>
        <small class="text-danger">{{ error.title }}</small>
      </b-form-group>

      <b-form-group
        id="input-group-receiver"
        label="Penerima Pesan:"
        label-for="input-receiver"
      >
        <b-form-select v-model="form.receiver" class="mb-3">
          <b-form-select-option value="0">Semua</b-form-select-option>
          <b-form-select-option value="1"
            >Kepala Keluarga Saja</b-form-select-option
          >
          <b-form-select-option value="2"
            >Ibu Rumah Tangga Saja</b-form-select-option
          >
          <b-form-select-option value="3">Anak Saja</b-form-select-option>
        </b-form-select>
        <small class="text-danger">{{ error.receiver }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description">Isi Pesan: </label>
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Isi Pesan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <label for="" class="label-bold">Tanggal Kirim Pesan:</label>
      <b-input-group>
        <div class="row mb-2">
          <div class="col-md-6">
            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="form.sending_time"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-input-group>
                  <b-input-group-prepend>
                    <button
                      class="btn btn-secondary"
                      type="button"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="fas fa-calendar"></i>
                    </button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="input-trigger-modal-patient"
                    v-model="form.sending_time"
                    placeholder="Tanggal (YYYY-MM-DD)"
                    readonly
                  >
                  </b-form-input>
                </b-input-group>
              </template>

              <v-date-picker
                v-if="modal2"
                v-model="form.sending_time"
                locale="id"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.sending_time)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <div class="col">
            <b-form-timepicker
              v-model="form.sending_time2"
              locale="id"
              :hour12="false"
              now-button
              reset-button
              label-now-button="waktu sekarang"
              label-close-button="tutup"
              placeholder="Jam"
            ></b-form-timepicker>
          </div>
        </div>
      </b-input-group>
      <small class="text-danger">{{ error.sending_time }}</small>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/announcements/list')"
      >
        Batal
      </b-button>
    </b-form>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    summonPurpose: {
      type: String,
      default: "master",
    },
  },

  data() {
    return {
      modal2: false,
      // Error
      error: {
        title: "",
        description: "",
        receiver: "",
        sending_time: "",
      },
    };
  },

  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");

        this.$router.push("/masters/announcements");
      }
    },
  },
};
</script>

<style>
</style>